<div id="sidebar" class="dark-theme" #sidebar>

  <div class="panels">
    <div id="panel-primary">

      <a routerLink="/Home" class="logo">
        <img src="assets/img/ait-logo-full.svg" alt="Author-it logo" [hidden]="!isOpen()">
        <img src="assets/img/ait-logo-icon.svg" alt="Author-it logo" [hidden]="isOpen()">
      </a>

      <div class="menu main-menu">
        <ul>
          <li *ngFor="let menuItem of mainMenuItems" [appRemoveIfFeatureOff]="menuItem.featureName"
            [id]="menuItem.featureName" [hidden]="menuItem.hidden"
            [ngClass]="isActiveRoute(menuItem.route) ? ' active' : ''"
            [title]="hasToolTip(menuItem.featureName) ? (menuItem.exactTitle || resources.localisedStrings[menuItem.titleRef]) : ''">
            <ng-template [ngIf]="!menuItem.action" [ngIfElse]="hasCustomAction">
              <a [queryParams]="menuItem.queryParams"
                [routerLink]="(menuItem.routeExtras ? menuItem.route + menuItem.routeExtras : menuItem.route)">
                <app-icon [iconName]="menuItem.icon" customClass="primaryMenu" fixedWidth size="sm"></app-icon>
                <span class="menuItemLabel">
                  {{menuItem.exactTitle || resources.localisedStrings[menuItem.titleRef]}}</span>
              </a>
            </ng-template>
            <ng-template #hasCustomAction>
              <a (click)="customAction(menuItem.action)">
                <app-icon [iconName]="menuItem.icon" customClass="primaryMenu" fixedWidth size="sm"></app-icon>
                <span class="menuItemLabel">
                  {{menuItem.exactTitle || resources.localisedStrings[menuItem.titleRef]}}</span>
              </a>
            </ng-template>

          </li>
        </ul>
      </div>

      <div class="menu lower-menu">
        <ul>
          <li *ngFor="let menuItem of secondaryMenuItems" [appRemoveIfFeatureOff]="menuItem.featureName"
            [id]="menuItem.featureName" [hidden]="menuItem.hidden"
            [ngClass]="isActiveRoute(menuItem.route) ? ' active' : ''"
            [title]="hasToolTip(menuItem.featureName) ? (menuItem.exactTitle || resources.localisedStrings[menuItem.titleRef]) : ''">

            <ng-template [ngIf]="!menuItem.externalUrl && !menuItem.action">
              <a [queryParams]="menuItem.queryParams"
                [routerLink]="(menuItem.routeExtras ? menuItem.route + menuItem.routeExtras : menuItem.route)">
                <app-icon [iconName]="menuItem.icon" customClass="primaryMenu" fixedWidth size="sm"></app-icon>
                <span class="menuItemLabel">
                  {{menuItem.exactTitle || resources.localisedStrings[menuItem.titleRef]}}</span>
              </a>
            </ng-template>
            <ng-template [ngIf]="!!menuItem.externalUrl">
              <a [href]="menuItem.route" target='_blank'>
                <app-icon [iconName]="menuItem.icon" customClass="primaryMenu" fixedWidth size="sm"></app-icon>
                <span class="menuItemLabel">
                  {{menuItem.exactTitle || resources.localisedStrings[menuItem.titleRef]}}</span>
              </a>
            </ng-template>
            <ng-template [ngIf]="!!menuItem.action">
              <a (click)="customAction(menuItem.action)">
                <app-icon [iconName]="menuItem.icon" customClass="primaryMenu" fixedWidth size="sm"></app-icon>
                <span class="menuItemLabel">
                  {{menuItem.exactTitle || resources.localisedStrings[menuItem.titleRef]}}</span>
              </a>
            </ng-template>

          </li>
        </ul>
      </div>
    </div>

    <button class="toggle" (click)="toggleBtn()">
      <app-icon class="close" iconName="back"></app-icon>
      <app-icon class="open" iconName="next"></app-icon>
    </button>

  </div>
</div>
<app-author-dialog #authorDialog></app-author-dialog>
<app-autologout-dialog #logoutDialog></app-autologout-dialog>
<app-complete-review-dialog #completeReviewDialog></app-complete-review-dialog>