<div class="dialog dark">
  <div class="dialogContent">
    <h3>{{config.data.title}}</h3>
    <p>
      {{config.data.message}}
    </p>
  </div>
  <div class="dialogFooter">
    <button *ngIf="showYesButton()" type="button" class="btn primary" (click)="yesClicked()">{{config.data.yesButton.label}}</button>
    <button *ngIf="showNoButton()" type="button" class="btn flat" (click)="noClicked()">{{config.data.noButton.label}}</button>
  </div>
</div>