import { DialogSettingsModel } from "src/app/models/dialog-settings.model";

export class DialogBase {
  public settings: DialogSettingsModel = {
    id: 'dialog',
    isModal: true,
    visible: false,
    showCloseIcon: false, //defaults to false
    target: null, //Specifies the target element in which to display the dialog. The default value is null, which refers the document.body element.
    cssClass: 'dark'
  };
  public dialogStyle = '';

  constructor(customSettings?: DialogSettingsModel) {
    if (customSettings) {
      this.settings = { ...this.settings, ...customSettings };
    }
  }

  public hide() {
    this.settings.visible = false;
  }

  public show() {
    this.settings.visible = true;
  }
}