import { Component } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig
  ) {}

  showYesButton(): boolean {
    return !!this.config.data?.yesButton;
  }

  showNoButton(): boolean {
    return !!this.config.data?.noButton;
  }

  noClicked() {
    this.config.data?.noButton?.click?.call();
  }

  yesClicked() {
    this.config.data?.yesButton?.click?.call();
  }
}
