<p-dialog #launchAuthorDialog [id]="settings.id" [class]="settings.cssClass" [visible]="settings.visible"
    [style]="{ width:'500px' }" [modal]="settings.isModal" [closable]="settings.showCloseIcon"
    [closeOnEscape]="true" [appendTo]="settings.target">
  <h3>{{resources.localisedStrings.launchAuthorDialogTitle}}</h3>
  <ul class="messages" *ngIf="messages?.length">
    <li *ngFor="let message of messages">
      <span [class]="message.type">{{message.text}}</span>
    </li>
  </ul>
  <p>
    {{resources.localisedStrings.launchAuthorDialogDescription}}
  </p>

  <h4>{{resources.localisedStrings.openInBrowserTitle}}</h4>
  <p>
    {{resources.localisedStrings.openInBrowserDescription}}
  </p>
  <button type="button" id="authorDialog_btnBrowser" class="btn primary"
    (click)="openBrowser();">{{resources.localisedStrings.openInBrowserLabel}}</button><br />

  <h4>{{resources.localisedStrings.launchRdpSessionTitle}}</h4>
  <p>
    {{resources.localisedStrings.launchRdpSessionDescription}}
  </p>
  <div class="copy-btn">
    <a class="copy-anchor"
      (click)="copyRdpPasswordToClipboard()">{{resources.localisedStrings.copyPasswordLinkText}}</a>
  </div>
  <a class="btn primary" id="authorDialog_btnRdp" [href]="" (click)="openDesktop()"
    target="_blank">{{resources.localisedStrings.launchRdpSessionLabel}}</a><br />

  <button class="btn flat icon close" title="{{resources.localisedStrings.closeLabel}}"
    (click)="hide()">
    <app-icon iconName="close"></app-icon>
  </button>
</p-dialog>