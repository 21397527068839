<p-dialog #completeReviewDialog [id]="settings.id" [class]="settings.cssClass" [visible]="settings.visible"
    [style]="{ width: '500px' }" [modal]="settings.isModal" [closable]="settings.showCloseIcon"
    [closeOnEscape]="settings.showCloseIcon" [appendTo]="settings.target">
    <h3>{{data.dialogTitle}}</h3>
    <div class="dialogContent">
      <ng-template [ngIf]="isCollaborationReview">
        <span>
          {{this.resources.localisedStrings.completeReviewDialogDescription}}
          {{this.resources.localisedStrings.cannotBeUndone}}
        </span>

      </ng-template>
      <!-- specifically false, not undefined -->
      <ng-template [ngIf]="isCollaborationReview === false">
        <h4>{{data.bookName}}</h4>
        <p>
          <span class="dateSection"><span class="dateLabel">{{data.startDateLabel}} </span>{{data.startDate}}</span>
          <span><span class="dateLabel">{{data.endDateLabel}} </span>{{data.endDate}}</span>
        </p>

        <p>
          {{data.summaryText}}
        </p>

        <p>
          <ng-template *ngIf="approvedNodes.length <= 0; then fail else success"></ng-template>
          {{data.completeReviewApproved}}
        </p>
        <p>
          <ng-template *ngIf="reviewNodes.length > 0; then fail else success"></ng-template>
          {{data.completeReviewToBeApproved}}
        </p>
        <p>
          <ng-template *ngIf="rejectedNodes.length > 0; then fail else success"></ng-template>
          {{data.completeReviewRejected}}
        </p>
        <p>
          <ng-template *ngIf="otherNodes.length > 0; then fail else success"></ng-template>
          {{data.completeReviewOther}}
        </p>

        <ng-template #fail>
          <app-icon iconName="cross" fixedWidth customClass="red"></app-icon>
        </ng-template>
        <ng-template #success>
          <app-icon iconName="tick" fixedWidth customClass="green"></app-icon>
        </ng-template>

        <hr>
        <div *ngIf="data.showCheck">
          <p-checkbox inputId="approve-topics" [checked]="data.checked" [binary]="true" (onClick)="validateCanComplete()"/>
          <label for="approve-topics">{{data.checkLabel}}</label>
        </div>

      </ng-template>
    </div>
    <div class="dialogFooter">
      <button type="button" class="btn primary" [disabled]="disableComplete"
        (click)="completeReview()">{{resources.localisedStrings.complete}}</button>
      <button type="button" class="btn info"
        (click)="hide()">{{resources.localisedStrings.cancel}}</button>
    </div>
</p-dialog>