import { Component, ViewChild } from '@angular/core';
import { ConfigService } from 'src/app/services/config/config.service';
import { ResourceService } from 'src/app/services/resource/resource.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { DialogBase } from '../dialog-base.class';
import { Dialog } from 'primeng/dialog';

@Component({
  selector: 'app-autologout-dialog',
  templateUrl: './autologout-dialog.component.html',
  styleUrls: ['./autologout-dialog.component.scss'],
})
export class AutologoutDialogComponent extends DialogBase {
  @ViewChild('autoLogoutDialog') dialog: Dialog;

  constructor(
    public configService: ConfigService,
    public resources: ResourceService,
    private authService: AuthenticationService
  ) {
    super({
      cssClass: 'dark',
      isModal: true,
      visible: false,
      id: 'auto-logout-dialog',
    });
  }

  loginAgain() {
    this.authService.logout();
    this.hide();
  }
}
