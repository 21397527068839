import { Injectable } from '@angular/core';
import { DynamicDialogRef, DialogService as PrimeNGDialogService } from 'primeng/dynamicdialog';
import { ConfirmDialogComponent } from 'src/app/components/dialogs/confirm-dialog/confirm-dialog.component';

type DialogPropsButtonCallback = () => void;
interface DialogPropsButton {
  click?: DialogPropsButtonCallback;
  label?: string;
}
interface DialogProps {
  title: string;
  message: string;
  yesButton?: DialogPropsButton;
  noButton?: DialogPropsButton;
  cssClass?: string;
}

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private dialogInstance: DynamicDialogRef | undefined;
  private dialogProps: DialogProps;

  constructor(private dialogService: PrimeNGDialogService) {}

  /**
   * Show a dialog message to the user
   * @param title Toast title
   * @param message Toast message
   */
  public showDialog(properties: DialogProps) {
    this.dialogProps = properties;

    // Has yes button?
    if (!!properties.yesButton) {
      this.dialogProps.yesButton = properties.yesButton;
    }
    // Has no button?
    if (!!properties.noButton) {
      this.dialogProps.noButton = properties.noButton;
    }

    // Show dialog
    this.dialogInstance = this.dialogService.open(ConfirmDialogComponent, {
      data: this.dialogProps,
      closable: false,
      width: '500px',
    });
  }

  /**
   * Hide dialog
   */
  public hideDialog() {
    if (this.dialogInstance) {
      this.dialogInstance.close();
      this.dialogInstance = null;
    }
  }
}
