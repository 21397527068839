<div class="wrap">
  <div class="background-strip"></div>

  <p-dialog #loginDialog [id]="settings.id" [class]="settings.cssClass" [style]="{ width: '500px' }"
    [visible]="settings.visible" [modal]="settings.isModal" [closable]="settings.showCloseIcon"
    [appendTo]="settings.target">
    <div class="logo"><img src='assets/img/ait-logo-full.svg' alt="Author-it logo" /></div>
    <div *ngIf="isLoginStep(1);" class="title">{{resources.localisedStrings.logInTitle}}</div>
    <div *ngIf="isLoginStep(2);" class="title">{{resources.localisedStrings.changeLibraryTitle}}</div>

    <ul class="messages" *ngIf="messages?.length">
      <li *ngFor="let message of messages">
        <span (click)="messageClick(message.controlName)" [class]="message.type">{{message.text}}</span>
      </li>
    </ul>
    <form [formGroup]="loginForm">

      <div *ngIf="isLoginStep(1);">
        <div class="control">
          <span class="p-float-label">
            <input type="text" pInputText formControlName="email" id="email" name="email"/>
            <label for="email">{{resources.localisedStrings.emailLabel}}</label>
          </span>
        </div>
        <div class="control">
          <span class="p-float-label">
            <input type="password" pInputText formControlName="password" id="password" name="password"/>
            <label for="password">{{resources.localisedStrings.passwordLabel}}</label>
          </span>
        </div>
        <div class="buttons">
          <button type="submit" [disabled]="loginForm.disabled" (click)="login()"
            class="btn primary" [ngClass]="loading ? 'loading': ''">
            <ng-template [ngIf]="!loginForm.disabled" [ngIfElse]="disabledButtonContent">
              {{resources.localisedStrings.continueLabel}}
            </ng-template>
          </button>
        </div>
      </div>

      <div *ngIf="isLoginStep(2);">
        <div class="control">
          <p-dropdown #library formControlName="library" [options]="libraryData" optionLabel="name"
            [placeholder]='resources.localisedStrings.libraryLabel' (onChange)="onLibrarySelected($event)"></p-dropdown>
        </div>
        <div class="buttons">
          <button type="submit" [disabled]="loginForm.disabled" (click)="loginWithLibrary()"
            class="btn primary" [ngClass]="loading ? 'loading': ''">
            <ng-template [ngIf]="!loginForm.disabled" [ngIfElse]="disabledButtonContent">
              {{resources.localisedStrings.logInLabel}}
            </ng-template>
          </button>
        </div>
      </div>

      <!-- text and icon to display when login etc button is disabled -->
      <ng-template #disabledButtonContent>
        {{resources.localisedStrings.loadingLabel}}&nbsp;
        <app-icon iconName="loading" [spin]="true"></app-icon>
      </ng-template>

    </form>

    <div *ngIf="isLoginStep(1);">
      <div class="below-dialog">
        <a href="{{configService.newAccountUrl}}" target="_blank">{{resources.localisedStrings.dontHaveAnAccountLink}}</a><br />
        <a *ngIf="!authService.ssoDomain" (click)="forgotPassword()">{{resources.localisedStrings.forgotYourPasswordLink}}</a><br />
      </div>
    </div>

    <div *ngIf="isLoginStep(2) && !authService.ssoDomain">
      <div class="below-dialog">
        <a (click)="backToLogin()">{{resources.localisedStrings.goBackLink}}</a>
      </div>
    </div>
  </p-dialog>
  <div class="locales">
    <a *ngIf="resources.getLocale()!=='en'" (click)="changeLocale('en')">
      <img src="assets/img/en-16h.png" alt="" />English</a>
    <a *ngIf="resources.getLocale()!=='de'" (click)="changeLocale('de')">
      <img src="assets/img/de-16h.png" alt="" />Deutsch</a>
    <a *ngIf="resources.getLocale()!=='fr'" (click)="changeLocale('fr')">
      <img src="assets/img/fr-16h.png" alt="" />Français</a>
  </div>
</div>
