<p-dialog #autoLogoutDialog [id]="settings.id" [class]="settings.cssClass" [visible]="settings.visible"
    [style]="{ width:'500px' }" [modal]="settings.isModal" [closable]="settings.showCloseIcon"
    [appendTo]="settings.target">
    <h3>{{resources.localisedStrings.sessionExpiredTitle}}</h3>
    <p>
      {{resources.localisedStrings.sessionExpiredDescription}}
    </p>

    <button type="button" class="btn primary"
      (click)="loginAgain();">{{resources.localisedStrings.logInAgainTitle}}</button>
</p-dialog>